<template>
    <div>
      <div id="container" :style="'height:' + pmHeight + 'px;'"></div>
      <div class="search-box" v-if="!this.showPopup">
        <div class="search-box-one" style="margin-top:15px;margin-bottom: 15px;;" @click="showList">
          <van-icon class="search-icon" name="search" />
          <div
            class="input-sty"
          >输入目的地</div>
        </div>
        <div style="width:90%;margin-left:5%;background: #FAFAFB;border-radius: 10px;margin-top: 15px;padding: 5px 10px;" v-if="pointInfo!=null">
          <div style="display:flex;">
            <div><van-icon name="location-o" style="font-size:14px;margin-right: 3px;"/>起点：</div>
            <div>融创森与海之城一区</div>
          </div>
          <div style="width:95%;margin-left:5%; height:1px;background:#f2f2f2;margin-top: 5px;"></div>
          <div style="display:flex;margin-top: 5px;">
            <div><van-icon name="location-o" style="font-size:14px;margin-right: 3px;"/>终点：</div>
            <div>{{pointInfo.name}}</div>
          </div>
          <div style="width:95%;margin-left:5%; height:1px;background:#f2f2f2;margin-top: 5px;"></div>
          <div style="display:flex;margin-top: 9px;">
            <div>距离最短：全程{{((drevingInfo.routes[0].distance) / 1000).toFixed(1)}}公里，确定信息无误后，请点击下一步</div>
          </div>
        </div>
        <div class="next" v-if="pointInfo!=null">
          下一步
        </div>
      </div>
      <van-popup v-model="showPopup" round  position="bottom" :style="{ height: '400px' }">
        <div style="position: relative;">
          <div class="search-box1">
            <div class="search-box-one">
              <van-icon class="search-icon" name="search" />
              <input
                class="input-sty"
                type="text"
                v-model="keyword"
                @input="inputKey"
                placeholder="输入目的地"
              />
            </div>
          </div>
          <div style="width:90%;height:400px;margin-left:5%;padding-top: 60px;overflow-y: scroll;" class="gdt">
            <div v-for="(item,index) in searchList" :key="index" class="address-item" @click="selPoint(item)">
              <div style="width:78%;display: flex;align-items: center;">
                <van-icon name="location-o" style="font-size:18px;"/>
                <div style="margin-left:5px;width:100%;">
                    <div style="font-size: 15px;font-weight:700;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;-o-text-overflow:ellipsis;">
                    {{item.name}}
                    </div>
                    <div style="font-size:12px;margin-top:2px;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;-o-text-overflow:ellipsis;">
                      {{item.district}}{{item.address}}
                    </div>
                </div>
              </div>
              <div>
                路线
              </div>
            </div>
          </div>
        </div>
      </van-popup>
      <!-- <div v-for="item in searchList" :key="item" @click="selPoint(item)">{{item.name}}</div> -->
    </div>
  </template>
  <script>
    
  let timers = null;
  import { Icon, Popup } from "vant";
  import AMap from "AMap"; // 引入高德地图
  var driving;
  export default {
    components: {
      VanIcon: Icon,
      VanPopup: Popup,
    },
    data() {
      return {
        map: null,
        autoComplete: null,
        keyword: null,
        searchList: [],
        pmHeight: 500,
        showPopup: true,
        pointInfo: null,
        drevingInfo: null
      };
    },
    mounted() {
      this.pmHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      this.initMap();
    },
    methods: {
      showList() {
        this.showPopup = true
      },
      inputKey() {
        this.search();
      },
      selPoint(e) {
        console.log(e, 123);
        var _this = this;
        if (driving) {
          driving.clear();
        }
        AMap.plugin("AMap.Driving", function () {
          driving = new AMap.Driving({
            // 驾车路线规划策略，AMap.DrivingPolicy.LEAST_TIME是最快捷模式
            policy: AMap.DrivingPolicy.LEAST_DISTANCE,
            // map 指定将路线规划方案绘制到对应的AMap.Map对象上
            map: _this.map,
            // panel 指定将结构化的路线详情数据显示的对应的DOM上，传入值需是DOM的ID
            // panel: 'container'
          });
  
          var startLngLat = [120.246232, 29.986425];
          var endLngLat = [e.location.lng, e.location.lat];
          console.log(endLngLat);
          _this.pointInfo = e
          driving.search(startLngLat, endLngLat, function (status, result) {
            // 未出错时，result即是对应的路线规划方案
            console.log(status);
            console.log(result);
            _this.drevingInfo = result
          });
          _this.showPopup = false
        });
      },
      search() {
        var _this = this;
        clearTimeout(timers)
        timers = setTimeout(() => {
            this.autoComplete.search(this.keyword, function (status, result) {
            // 搜索成功时，result即是对应的匹配数据
            console.log(status);
            console.log(result);
            _this.searchList = result.tips;
          });
        }, 300);
      },
      initMap() {
        var _this = this;
        const dom = document.getElementById("container");
        this.map = new AMap.Map(dom, {
          resizeEnable: true,
          zoom: 15,
          center: [120.246232, 29.986425],
          showLabel: true, //不显示地图文字标记, 终于可以配置了TAT
        });
        // // JSAPI 2.0 输入提示插件名称由 AMap.Autocomplete 变更为 AMap.AutoComplete 啦！
        // AMap.plugin('AMap.ToolBar',function(){//异步加载插件
        //     var toolbar = new AMap.ToolBar();
        //     _this.map.addControl(toolbar);
        // });
  
        AMap.plugin("AMap.AutoComplete", function () {
          var autoOptions = {
            //city 限定城市，默认全国
            city: "全国",
          };
          // 实例化AutoComplete
          _this.autoComplete = new AMap.Autocomplete(autoOptions);
        });
      },
    },
  };
  </script>
  <style  scoped>
  #container {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 600px;
  }
  .search-box1 {
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    display: flex;
    background: #fff;
    height: 60px;
    align-items: center;
    z-index: 99999999999;
  }
  .search-box {
    position: fixed;
    left: 0;
    bottom: 0px;
    width: 100%;
    display: flex;
    background: #fff;
    min-height: 60px;
    flex-direction: column;
    /* align-items: center; */
    z-index: 99999999999;
  }
  .search-box-one {
    position: relative;
    width: 90%;
    margin-left: 5%;
    height: 40px;
    border: 2px solid #f2f2f2;
    border-radius: 40px;
    display: flex;
    align-items: center;
  }
  .input-sty {
    width: 80%;
    margin-left: 2%;
    height: 35px;
    line-height: 35px;
    border: none;
  }
  .search-icon {
    font-size: 20px;
    margin-left: 10px;
  }
  .address-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #f2f2f2;
  }
  .gdt::-webkit-scrollbar {
      display: none; /* Chrome Safari */
  }
  .next {
    width:90%;
    margin-left: 5%;
    height: 39px;
    background: #2D83E8;
    margin-top: 25px;;
    border-radius: 40px;
    text-align: center;
    line-height: 39px;
    color: #fff;
    font-size: 16px;;
    margin-bottom: 25px;;
  }
  </style>